import { Routes } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../../modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('../../modules/roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'permission',
    loadChildren: () => import('../../modules/permission/permission.module').then(m => m.PermissionModule)
  },
  {
    path: 'category',
    loadChildren: () => import('../../modules/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'product',
    loadChildren: () => import('../../modules/product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'accounthead',
    loadChildren: () => import('../../modules/accounts/accounthead/pages/accounthead.module').then(m => m.AccountHeadModule)
  },
  {
    path: 'business',
    loadChildren: () => import('../../modules/accounts/business/pages/business.module').then(m => m.BusinessModule)
  },
  {
    path: 'account',
    loadChildren: () => import('../../modules/accounts/account/pages/account.module').then(m => m.AccountModule)
  },
  {
    path: 'dbbackup',
    loadChildren: () => import('../../modules/dbbackup-restore/databasebackup/dbbackup.module').then(m => m.DatabaBaseBackupModule)
  },
  {
    path: 'dbrestore',
    loadChildren: () => import('../../modules/dbbackup-restore/databaserestore/databaserestore.module').then(m => m.DatabaBaseRestoreModule)
  },
  {
    path: 'joborder',
    loadChildren: () => import('../../modules/placeorder/placeorder.module').then(m => m.PlaceOrderModule)
  },
  {
    path: 'productattribute',
    loadChildren: () => import('../../modules/productattribute/productattribute.module').then(m => m.ProductAttributeModule)
  },
  {
    path: 'store',
    loadChildren: () => import('../../modules/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'estimation',
    loadChildren: () => import('../../modules/ordermanagement/salesestimation/salesestimation.module').then(m => m.SalesEstimationModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('../../modules/ordermanagement/sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'purchase',
    loadChildren: () => import('../../modules/ordermanagement/purchase/purchase.module').then(m => m.PurchaseModule)
  },
  {
    path: 'salesreturn',
    loadChildren: () => import('../../modules/ordermanagement/salesreturn/salesreturn.module').then(m => m.SalesReturnModule)
  },
  {
    path: 'purchasereturn',
    loadChildren: () => import('../../modules/ordermanagement/purchasereturn/purchase.return.module').then(m => m.PurchaseReturnModule)
  },
  {
    path: 'taxhsncode',
    loadChildren: () => import('../../modules/taxhsncode/pages/taxhsncode.module').then(m => m.TaxHsnModule)
  },
  {
    path: 'designtaskboard',
    loadChildren: () => import('../../modules/taskboard/taskboard.module').then(m => m.DesignTaskboardModule)
  },
  {
    path: 'designboard',
    loadChildren: () => import('../../modules/designboard/designorder/design.module').then(m => m.DesignModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/designdashboard/dashboard/dashboard.module').then(m => m.DesignDashboardModule)
  },
  {
    path: 'workorder',
    loadChildren: () => import('../../modules/workorder/workorder.module').then(m => m.WorkOrderModule)
  },
  {
    path: 'rootcard',
    loadChildren: () => import('../../modules/workorderboard/workorder-board.module').then(m => m.WorkOrderBoardModule)
  },
  {
    path: 'viewjoborders',
    loadChildren: () => import('../../modules/joborders/viewjoborder.module').then(m => m.ViewJobOrderModule)
  },
  {
    path: 'generateworkorder',
    loadChildren: () => import('../../modules/Generateworkorder/generateworkorder.module').then(m => m.GenerateOrderModule)
  },
  {
    path: 'orderinvoice',
    loadChildren: () => import('../../modules/ordermanagement/orderinvoice/order-invoice.module').then(m => m.OrderInvoiceModule)
  },
  {
    path: 'discount',
    loadChildren: () => import('../../modules/discount/discount.module').then(m => m.DiscountModule)
  },
  {
    path: 'paymentmode',
    loadChildren: () => import('../../modules/paymentmode/paymentmode.module').then(m => m.PaymentModeModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../modules/mastersettings/settings.module').then(m => m.SettingsModule)
  },
];
