import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, timer } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { RoutingService } from '../services/routing.service';
import { SharedService } from '../services/shared.Service';
import { DesignOrderService } from '../services/designorder/designorder.service';
import { AlertService } from '../services/alert.service';
import { switchMap } from 'rxjs/operators';
import { Response } from 'app/shared/models/response/response.model';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../services/session/AppService';
import { RolesService } from '../services/roles/roles.service';
import { PermissionService } from '../services/permission/permission.service';
import { environment } from 'environments/environment.defaults';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('content', { static: false }) content;
  currentLang = "en";
  selectedLanguageText = "English";
  selectedLanguageFlag = "./assets/img/flags/us.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  private roleList: Array<any> = [];
  searchOpenClass = "";
  name: any;
  selectedRole: any;
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  userFullName: string;
  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  @Output()
  seachTextEmpty = new EventEmitter<boolean>();
  closeResult: string;
  imageString: string;
  modalOptions: NgbModalOptions;
  listItems = [];
  control = new FormControl();
  public orderHistory: Array<any> = [];
  public config: any = {};
  role: string;
  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private permissionServece: PermissionService,
    private routing: RoutingService,
    private designOrderService: DesignOrderService, private alertService: AlertService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private appService: AppService,
    private roleService: RolesService,
    private configService: ConfigService, private cdr: ChangeDetectorRef) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.name = this.sharedService.userFullName;
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size: <any>'xl'
    }
    this.selectedRole = '';
    this.role = this.sharedService.userRole;
    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.getUserRoles();
    this.listItems = LISTITEMS;
    this.userFullName = this.sharedService.userFullName;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
    if (this.sharedService.userRole === 'Designer' ||this.sharedService.userRole === 'DesignApprover') {
      this.refreshNotificationData();
    }
    this.appService.getUserLoggedIn().subscribe((response) => {
      if (!response) {
        if (this.layoutSub) return;
        this.layoutSub.unsubscribe();
      }
    });
  }
  refreshNotificationData() {
    this.layoutSub = timer(0, environment.notificationIntervals).pipe(
      switchMap(() => this.designOrderService.getAllHistoryByUserId(this.sharedService.appUserId))
    ).subscribe((response: Response) => {
      if (response.failure) {
        //  this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.orderHistory = response.result;
        this.cdr.markForCheck();
      }
    });
  }
  getHistory() {
    this.designOrderService.getAllHistoryByUserId(this.sharedService.appUserId).subscribe((response: Response) => {
      if (response.failure) {
        //  this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.orderHistory = response.result;
        this.cdr.markForCheck();
      }
    });
  }
  onRoleChange(role) {
    this.routing.sideMenus = [];
    this.routing.role = '';
    this.sharedService.userRole = role;
    this.role = role;
    const roleId = (this.sharedService.where(this.roleList, 'name', role) || {}) as any;
    this.permissionServece.getPermissionByRoleId(roleId.id).subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.sharedService.permissionList = response.result;
        this.cdr.markForCheck();
        const url = this.routing.defaultRoutebyRole(this.sharedService.userRole);
        this.modalService.dismissAll();
        this.router.navigate([url]);
        location.reload();
      }
    });
  }
  getPermissionsByRoleId(): void {
    const roleId = (this.sharedService.where(this.roleList, 'name', this.sharedService.userRole) || {}) as any;
    this.permissionServece.getPermissionByRoleId(roleId.id).subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.sharedService.permissionList = response.result;
        this.cdr.markForCheck();
        const url = this.routing.defaultRoutebyRole(this.sharedService.userRole);
        this.redirectTo(url);
      }
    });
  }
  UpdateHistoryNotification(id: any) {
    this.designOrderService.updateNotificationHistory(id).subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else if (response.success) {
        this.getHistory();
        this.router.navigate(['../designtaskboard'], { relativeTo: this.activatedRoute });
      }
    });
  }
  ReadAllNotification() {
    this.designOrderService.readAllNotification().subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else if (response.success) {
        this.getHistory();
        this.router.navigate(['../designtaskboard'], { relativeTo: this.activatedRoute });
      }
    });
  }
  getUserRoles(): void {
    const UserId = this.sharedService.appUserId;
    this.roleService.getUserRoleByUserId(UserId).subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.roleList = response.result;
      }
    });
  }
  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != "") {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = "";
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue("");
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = "English";
      this.selectedLanguageFlag = "./assets/img/flags/us.png";
    }
    else if (language === 'es') {
      this.selectedLanguageText = "Spanish";
      this.selectedLanguageFlag = "./assets/img/flags/es.png";
    }
    else if (language === 'pt') {
      this.selectedLanguageText = "Portuguese";
      this.selectedLanguageFlag = "./assets/img/flags/pt.png";
    }
    else if (language === 'de') {
      this.selectedLanguageText = "German";
      this.selectedLanguageFlag = "./assets/img/flags/de.png";
    }
  }

  routeToAdd(item: any) {
    const designArt = item || null;
    this.sharedService.sessionData = { 'artInfo': designArt };
    this.router.navigate(['/designtaskboard/design'], { relativeTo: this.activatedRoute });
  }
  onStatusUpdate(status: any, event: any) {
    const action = event.target.outerText;
    this.alertService.confirmAsync('Are you sure do you want to ' + action + '?')
      .subscribe((res: boolean) => {
        if (res) {
          var designorder: any;
          designorder = {
            Id: status.id,
            HistoryId: status.historyId,
            AssigneeId: status.asigneeId,
            StatusId: 20
          };
          switch (action) {
            case 'Pending':
              designorder.StatusId = 30;
              break;
            case 'Reject':
              designorder.StatusId = 50;
              break;
            default:
              designorder.StatusId = 20;
              break;
          }
          this.designOrderService.updateDesignHistory(designorder).subscribe((response: Response) => {
            if (response.failure) {
              this.alertService.warnAlertAsync(response.error).subscribe();
            } else if (response.success) {
              this.alertService.successAlertAsync(response.result)
                .subscribe((res) => {
                  this.getHistory();
                  this.router.navigate(['../designtaskboard'], { relativeTo: this.activatedRoute });;
                });
            }
          });
        }
      });
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);
  }
  open(content, image: any) {
    this.imageString = image;
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openModel() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalService.open(this.content, ngbModalOptions);
  }
  close() {
    this.modalService.dismissAll();
    this.routing.sideMenus = [];
    this.routing.role = '';
    this.getPermissionsByRoleId();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  logout() {
    this.layoutSub.unsubscribe();
    this.appService.setUserLoggedIn(false);
    this.appService.setuserSessionTime(false);
    this.routing.sideMenus = [];
    this.routing.role = '';
    this.authService.logout();
  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }


}
