<router-outlet></router-outlet>
<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-child-name" class="modal-title pull-left">You Have Been Idle!</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{idleState}}
                <div class="row mrgbtm">
                    <div class="col-md-6 text-right">
                        <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
                    </div>
                    <div class="col-md-6 text-left">
                        <button type="button" (click)="stay()" class="btn btn-success">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div bsModal #sessionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-child-name" class="modal-title pull-left">Alert</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <h5 style="padding-left:20px;">Your Session has been completed and please login again.</h5>
                </div>
            </div>
        </div>
    </div>
</div>