import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, of, timer } from 'rxjs';
import { environment } from 'environments/environment';
import { ApiService } from '../services/api.service';
import { SharedService } from '../services/shared.Service';
import { Response } from '../models/response/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  static AUTH_PATH = `${environment.apiPath}users/` + 'authenticate';
  static Refresh_PATH = `${environment.apiPath}users/` + 'refresh';
  static Revoke_PATH = `${environment.apiPath}users/` + 'revoke-token';

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenTimeout;
  constructor(private apiService: ApiService, private sharedService: SharedService, private router: Router,
    private activatedRoute: ActivatedRoute) { }

  /**
   * Authenticate the User
   * @param userName user name
   * @param password password
   * @returns response object
   */
  signinUser(userName: string, password: string) {
    const request = {
      Email: userName,
      Password: password
    };
    return this.apiService.postData(AuthService.AUTH_PATH, request)
      .pipe(map((response: any) => {
        const res = this.populateSuccessResponse(response);
        if (res.failure) {
          return res;
        } else {
          this.sharedService.clear();
          this.sharedService.token = response.data.accessToken;
          this.sharedService.refreshToken = response.data.refreshToken;
          this.sharedService.tokenExpireTime = response.data.tokenExpiry;
          this.sharedService.appUserId = response.data.id;
          this.sharedService.email = response.data.email;
          this.sharedService.userFullName = response.data.firstName + response.data.lastName;
          this.sharedService.userName = response.data.userName;
          this.refreshTokenSubject.next(response);
          this.startRefreshTokenTimer();
          return res;
        }
      }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
  }

  /**
   * Logout the User from Application.
   */
  logout() {
    this.stopRefreshTokenTimer();
    if (this.sharedService.userRole) {
      this.refreshTokenSubject.next(null);
      this.revokeToken().subscribe(() => {
        console.log('Revoke token success response');
      }, (err: HttpErrorResponse) => {
        console.log(`Error occured while revoking token with error message ${err.message}`);
      });
      this.sharedService.clear();
      this.router.navigate(['/logout'], { relativeTo: this.activatedRoute });
    }
  }

  /**
   * Gets the token
   */
  getToken() {
    return this.sharedService.token;
  }

  /**
   * Gets the new refresh token.
   * @returns response object
   */
  getRefreshToken() {
    const request = {
      accessToken: this.sharedService.token,
      refreshToken: this.sharedService.refreshToken
    }
    return this.apiService.postData(AuthService.Refresh_PATH, request)
      .pipe(map((response: any) => {
        const res = this.populateSuccessResponse(response);
        if (res.failure) {
          return res;
        } else {
          this.sharedService.token = res.result.accessToken;
          this.sharedService.refreshToken = res.result.refreshToken;
          this.sharedService.tokenExpireTime = res.result.tokenExpiry;
          this.refreshTokenSubject.next(res.result);
          this.startRefreshTokenTimer();
          return res;
        }
      }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
  }

  /**
   * Invokes the Revoke Token API.
   */
  revokeToken() {
    const revokeTokenRequest = {
      Token: this.sharedService.refreshToken,
    }
    return this.apiService.postData(AuthService.Revoke_PATH, revokeTokenRequest)
      .pipe(map((response: any) => {
        const res = this.populateSuccessResponse(response);
        return res;
      }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
  }

  /**
   * Validates the User Authentication Response.
   * @returns boolean response
   */
  isAuthenticated() {
    return !!this.sharedService.token;
  }

  /**
   * Starts the Refresh Token Timer.
   */
  public startRefreshTokenTimer() {
    if (this.sharedService.token) {
      // // parse json object from base64 encoded jwt token
      // const jwtToken = JSON.parse(atob(this.sharedService.token.split('.')[1]));
      // // set a timeout to refresh the token a minute before it expires
      // const expires = new Date(jwtToken.exp * 1000);
      // const timeout = expires.getTime() - new Date().getTime() - (60 * 1000);

      const expires = +this.sharedService.tokenExpireTime - 1;
      const timeout = (expires * 60 * 1000);
      this.stopRefreshTokenTimer();
      this.refreshTokenTimeout = timer(timeout).pipe(switchMap(() => this.getRefreshToken())).subscribe();
    }
  }

  /**
   * Stop the Refresh Token Timer.
   */
  private stopRefreshTokenTimer() {
    if (!this.refreshTokenTimeout) return;
    this.refreshTokenTimeout.unsubscribe();
  }

  /**
     * Populates the Success Response.
     * @param response response object from API
     * @returns response model
     */
  private populateSuccessResponse(response: any) {
    const res: Response = {
      failure: !!response.isError,
      success: !!response.succeeded,
      error: !!response.isError ? response.responseException.exceptionMessage : response.message,
      result: response.data
    };
    if (response.statusCode === 400) {
      res.error = environment.errorMessages[400];
    }
    return res;
  }

  /**
   * Populates the Error Response.
   * @param error error response object from API.
   * @returns response model
   */
  private populateErrorResponse(error: any) {
    const res: Response = {
      failure: true,
      success: false,
      error: error.error.responseException.exceptionMessage.detail|| environment.errorMessages[error.statusCode],
      result: null
    };
    return res;
  }
}
