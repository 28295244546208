import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Roles } from 'app/shared/models/roles/roles.model';
import { Response } from 'app/shared/models/response/response.model';


@Injectable()
export class RolesService {
    static SEARCH_ROLES = `${environment.apiPath}roles/` + 'search';
    static GET_ROLES = `${environment.apiPath}roles`;
    static GET_USER_ROLES = `${environment.apiPath}users`;
    static SAVE_ROLE = `${environment.apiPath}roles`;
    static UPDATE_ROLE = `${environment.apiPath}roles`;

    constructor(private apiService: ApiService) { }

    /**
     * Search the roles.
     * @param searchObj the search query request model.
     * @returns response model
     */
    searchRole(searchObj: any): Observable<any> {
        const request = {
            Name: searchObj.roleName, IsActive: searchObj.isActive
        };
        return this.apiService.postData(RolesService.SEARCH_ROLES, request)
            .pipe(map((response: any) => {
                const res = this.populateSuccessResponse(response);
                if (res.failure) {
                    return res;
                } else {
                    res.result = this.populateRolesList(res.result);
                }
                return res;
            }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Gets the role details mapped to user by user id.
     * @param role The role request details
     * @returns response model
     */
    getUserRoleByUserId(role: any): Observable<any> {
        return this.apiService.getDetails(`${RolesService.GET_USER_ROLES}/${role}/role`).pipe(map((response: any) => {
            const res = this.populateSuccessResponse(response);
            if (res.failure) {
                return res;
            } else {
                res.result = this.populateRoles(res.result);
            }
            return res;
        }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Saves the role details.
     * @param role The role request details
     * @returns response model
     */
    saveRole(role: Roles): Observable<any> {
        return this.apiService.postData(RolesService.SAVE_ROLE, role)
            .pipe(map((result: any) => {
                const res = this.populateSuccessResponse(result);
                return res;
            }), catchError((err: any) => {
                return of(this.populateErrorResponse(err));
            }));
    }

    /**
     * Updates the role details.
     * @param role The role request details
     * @returns response model
     */
    updateRole(role: Roles): Observable<any> {
        return this.apiService.postData(RolesService.UPDATE_ROLE, role)
            .pipe(map((result: any) => {
                const res = this.populateSuccessResponse(result);
                return res;
            }), catchError((err: any) => {
                return of(this.populateErrorResponse(err));
            }));
    }

    /**
     * Populates the roles
     * @param roles The role list.
     */
    private populateRolesList(roles: any): any {
        const roleList = [];
        for (let index = 0; index < roles.length; index++) {
            const role = {
                sNo: index + 1,
                id: roles[index].id,
                roleName: roles[index].name,
                isActive: roles[index].isActive ? 'Yes' : 'No',
            };
            roleList.push(role);
        }
        return roleList;
    }

    /**
     * Populates the roles
     * @param roles The role list.
     */
    private populateRoles(roles: any): any {
        const roleList = [];
        for (let index = 0; index < roles.length; index++) {
            const role = {
                id: roles[index].roleId,
                name: roles[index].roleName,
            };
            roleList.push(role);
        }
        return roleList;
    }

    /**
     * Populates the Success Response.
     * @param response response object from API
     * @returns response model
     */
    private populateSuccessResponse(response: any) {
        const res: Response = {
            failure: !!response.isError,
            success: !!response.succeeded,
            error: !!response.isError ? response.responseException.exceptionMessage : response.message,
            result: response.data
        };
        if (response.statusCode === 400) {
            res.error = environment.errorMessages[400];
        }
        return res;
    }

    /**
     * Populates the Error Response.
     * @param error error response object from API.
     * @returns response model
     */
    private populateErrorResponse(error: any) {
        const res: Response = {
            failure: true,
            success: false,
            error: error.error.responseException.exceptionMessage.detail || environment.errorMessages[error.statusCode],
            result: null
        };
        return res;
    }

}