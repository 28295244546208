import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { switchMap, filter, take, catchError, retryWhen, delay, concatMap } from 'rxjs/operators';
import { SharedService } from '../services/shared.Service';
import { AppService } from '../services/session/AppService';
export const retryCount = 2;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(public auth: AuthService, private sharedService: SharedService, private appService: AppService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.getToken()) {
      request = this.addToken(request, this.auth.getToken());
    }
    return next.handle(request).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((error, count) => {
            if (count <= retryCount && [400, 401].includes(error.status) && request.url.indexOf('/refresh') > -1) {
              return of(error);
            }
            else if (count > retryCount && [400, 401].includes(error.status) && request.url.indexOf('/refresh') > -1) {
              this.appService.setuserSessionTime(true);
            }
            if (error instanceof HttpErrorResponse && error.status === 401 &&  error.error === null) {
              this.auth.logout();
            }
            return throwError(error);
          }),
          delay(0)
        )
      )
    )
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

}
