import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthService } from './shared/auth/auth.service';
import { RoutingService } from './shared/services/routing.service';
import { AppService } from './shared/services/session/AppService';
import { environment } from 'environments/environment.defaults';
import { Keepalive } from '@ng-idle/keepalive';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BsModalRef, ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { filter, map } from 'rxjs/operators';
import { SharedService } from './shared/services/shared.Service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    @ViewChild('childModal', { static: false }) childModal: ModalDirective;
    @ViewChild('sessionModal', { static: false }) sessionModal: ModalDirective;
    idleState = 'Not started.'; timedOut = false; lastPing?: Date = null; title = 'user-idle-timeout'; public modalRef: BsModalRef;
    subscription: Subscription;
    constructor(public idle: Idle, private keepalive: Keepalive, private authService: AuthService, private activatedRoute: ActivatedRoute,
        private router: Router, private modalService: BsModalService, private routing: RoutingService, private sharedService: SharedService,
        private appService: AppService, private routingService: RoutingService,) {
        // sets an idle timeout for testing purposes.
        idle.setIdle(environment.sessionTimeout);   // sets an idle timeout of 900 seconds, for testing purposes.
        idle.setTimeout(60); // sets a timeout period of 10 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.'
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this.childModal.hide();
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.authService.logout();
        });
        idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!';
            this.idle.clearInterrupts();
            this.childModal.show();
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!'
        });

        // sets the ping interval to 15 seconds
        keepalive.interval(15);

        keepalive.onPing.subscribe(() => this.lastPing = new Date());

        this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
            if (userLoggedIn) {
                idle.watch()
                this.timedOut = false;
            } else {
                idle.stop();
            }
        })
        this.appService.getuserSessionTime().subscribe(userSessionTime => {
            if (userSessionTime) {
                this.sessionModal.show();
                setTimeout(() => {
                    this.logout();
                }, 20000);
            }
        })
    }
    ngOnInit() {
        this.subscription = this.router.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe((event: NavigationStart) => {
            const url = this.routingService.navigateTo(event);
            if (event.url !== url) {
                this.router.navigate([url], { relativeTo: this.activatedRoute });
            }
        });

        if (this.sharedService.userRole) {
            this.appService.setUserLoggedIn(true);
            this.authService.getRefreshToken().subscribe();
        }
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    reset() {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.watch();
        //xthis.idleState = 'Started.';
        this.timedOut = false;
    }

    hideChildModal(): void {
        this.childModal.hide();
    }

    stay() {
        this.childModal.hide();
        this.reset();
    }

    logout() {
        this.childModal.hide();
        this.idle.stop();
        this.sessionModal.hide();
        this.appService.setUserLoggedIn(false);
        this.appService.setuserSessionTime(false);
        this.routing.sideMenus = [];
        this.routing.role = '';
        this.authService.logout();
    }
}


