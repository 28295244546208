import { Component, OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription, timer } from 'rxjs';
import { DesignOrderService } from '../services/designorder/designorder.service';
import { Response } from 'app/shared/models/response/response.model';
import { AlertService } from '../services/alert.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit, OnDestroy {
  layoutSub: Subscription;
  isOpen = false;
  private orderHistory: Array<any> = [];
  private artHistory: Array<any> = [];

  constructor(private layoutService: LayoutService, private designOrderService: DesignOrderService, private alertService: AlertService) {
    this.layoutSub = layoutService.toggleNotiSidebar$.subscribe(
      open => {
        this.isOpen = open;
      });
  }
  ngOnInit() {
    // this.layoutSub = timer(0, 60).pipe(
    //   switchMap(() => this.designOrderService.getAllHistoryWithOrder())
    // ).subscribe((response: Response) => {
    //   if (response.failure) {
    //     this.alertService.warnAlertAsync(response.error).subscribe();
    //   } else {
    //     this.orderHistory = response.result;
    //   }
    // });
  }
  getAllHistorywithOutOrder(): void {
    this.designOrderService.getAllHistoryByOrder().subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.artHistory = response.result;
      }
    });
  }
  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClose() {
    this.layoutService.toggleNotificationSidebar(false);
  }

}
