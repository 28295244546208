<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <!-- <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:"
            (click)="toggleSearchOpenClass(true)"><i class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="Explore Apex..." tabindex="0"
              autofocus data-search="template-search" [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()"
              (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name"
                    (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()">
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>
      </ul> -->
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div><span
                    class="text">Gold Soft</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown >
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                [src]="selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')"><img
                  class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" /><span
                  class="font-small-3">English</span></a><a class="dropdown-item" href="javascript:;" data-language="es"
                (click)="ChangeLanguage('es')"><img class="langimg mr-2" src="./assets/img/flags/es.png"
                  alt="flag" /><span class="font-small-3">Spanish</span></a><a class="dropdown-item" href="javascript:;"
                data-language="pt" (click)="ChangeLanguage('pt')"><img class="langimg mr-2"
                  src="./assets/img/flags/pt.png" alt="flag" /><span class="font-small-3">Portuguese</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="de" (click)="ChangeLanguage('de')"><img
                  class="langimg mr-2" src="./assets/img/flags/de.png" alt="flag" /><span
                  class="font-small-3">German</span></a>
            </div>
          </li> -->

          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3"></i><span
                class="notification badge badge-pill badge-danger">{{orderHistory.length}}</span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">
              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span
                      class="noti-title">{{orderHistory.length}} New
                      Notification</span>
                  </div>
                  <!-- <span class="text-bold-400 cursor-pointer" (click)="ReadAllNotification()">Mark all as read</span> -->
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a class="d-flex justify-content-between read-notification" *ngFor="let order of orderHistory"
                  href="javascript:void(0)">
                  <div class="media d-flex align-items-center py-0 pr-0">
                    <div class="media-left">
                      <div class="mr-3">
                        <img
                          src="{{order.imageString === '' ? 'assets/img/icons/sketch-mac-icon.png' :order.imageString }}"
                          alt="avatar" height="45" width="45"
                          (click)="open(mymodal, order.imageString === '' ? 'assets/img/icons/sketch-mac-icon.png' :order.imageString)" />
                      </div>
                    </div>
                    <div class="media-body" (click)="routeToAdd(order);">
                      <h6 class="m-0">{{order.orderNo}}</h6>
                      <small class="noti-text">{{order.message}}</small> <br>
                      <span class="noti-text">{{order.state}}</span>
                      <p class="noti-text">{{order.createdOn}}</p>
                    </div>
                    <div class="media-right" *ngIf="role ==='DesignApprover' && order.status === 'Waiting'; else read">
                      <div class="border-left">
                        <div class="px-3 py-1  border-bottom">
                          <span class="m-0 text-bold-600 text-success" value="Approve"
                            (click)="onStatusUpdate(order, $event)">Approve</span>
                        </div>
                        <div class="px-3 py-1 border-bottom">
                          <span class="m-0 text-bold-600 text-danger" value="ReJecte"
                            (click)="onStatusUpdate(order, $event)">Reject</span>
                        </div>
                        <div class="px-3 py-1 border-bottom">
                          <span class="m-0 text-bold-600 text-warning" value="Pending"
                            (click)="onStatusUpdate(order, $event)">Pending</span>
                        </div>
                      </div>
                    </div>
                    <ng-template class="media-right" #read>
                      <div class="border-left">
                        <div class="px-3 py-1  border-bottom">
                          <span class="m-0 text-bold-600 text-success"
                            (click)="UpdateHistoryNotification(order.notificationId)">Read</span>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </a>
              </li>
              <!-- <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  Read All Notifications
                </div>
              </li> -->
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{userFullName}}</span><span
                  class="text-right text-muted font-small-3">{{role}}</span>
              </div>
              <!-- <img class="avatar" src="assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35" width="35" /> -->
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item" routerLink="/chat">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/pages/profile">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My Inbox</span>
                </div>
              </a> -->
              <a class="dropdown-item py-1" (click)="openModel()">
                <i class="ft-edit mr-2"></i>
                <span>Change Role</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="ft-align-right font-medium-3"></i></a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>

  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Attachments</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div style="width: 100%; overflow-x: scroll; height: 1000px; overflow-y: scroll;">
        <lib-ngx-image-zoom [thumbImage]=imageString [fullImage]=imageString [magnification]="1"
          [enableScrollZoom]="true" [enableLens]="true" [lensHeight]="300" [lensWidth]="300"></lib-ngx-image-zoom>
      </div>
      <!-- <img src="{{ imageString }}" alt="designs" (click)="open(mymodal, item.imageString)"> -->
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
  </ng-template>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{name}}</h4>
    </div>
    <div id class="modal-body">
      <form>
        <div class="form-group">
          <label for="store">Roles</label>
          <div class="input-group">
            <select id="store" class="form-control" [ngModel]="selectedRole" (ngModelChange)="onRoleChange($event)"
              [ngModelOptions]="{standalone: true}">
              <option [value]="''">Select Role</option>
              <option *ngFor="let role of roleList" [value]="role.name">
                {{role.name}}
              </option>
            </select>

            <!-- <div>
                        <button type="button" class="btn btn-primary d-lg-flex align-items-center" (click)="close()"><i class="fa fa-check-circle"></i>Update</button>
                      </div> -->
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</nav>