import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class SharedService {
    dataBind: Function;
    private username: string;
    sessionData: { [key: string]: object };
    storage: Storage = sessionStorage;
    get expiryKey() {
        return localStorage.getItem('ng2Idle.main.expiry');
    }
    set userRole(value: string) {
        this.storage.setItem('userRole', btoa(value));
    }
    get userRole() {
        const userRole = this.storage.getItem('userRole');
        try {
            return userRole ? atob(userRole) : '';
        } catch (error) {
            return '';
        }
    }
    set userFullName(value: string) {
        this.storage.setItem('userFullName', btoa(value));
    }
    get userFullName() {
        const userFullName = this.storage.getItem('userFullName');
        try {
            return userFullName ? atob(userFullName) : '';
        } catch (error) {
            return '';
        }
    }
    set sideMenus(value: Array<any>) {
        this.storage.setItem('sideMenus', btoa(JSON.stringify(value)));

    }
    get sideMenus() {
        const sideMenus = this.storage.getItem('sideMenus');
        try {
            return sideMenus ? JSON.parse(atob(sideMenus)) : [];
        } catch (error) {
            return [];
        }
    }
    set appUserId(value: string) {
        this.storage.setItem('userid', value);
    }
    get appUserId() {
        const userid = this.storage.getItem('userid');
        try {
            return userid ? userid : '';
        } catch (error) {
            return '';
        }
    }
    set storeId(value: string) {
        this.storage.setItem('storeId', value);
    }
    get storeId() {
        const storeId = this.storage.getItem('storeId');
        try {
            return storeId ? storeId : '';
        } catch (error) {
            return '';
        }
    }
    set tokenExpireTime(value: string) {
        this.storage.setItem('tokenExpireTime', value);
    }
    get tokenExpireTime() {
        const tokenExpireTime = this.storage.getItem('tokenExpireTime');
        try {
            return tokenExpireTime ? tokenExpireTime : '';
        } catch (error) {
            return '';
        }
    }
    set financialYear(value: string) {
        this.storage.setItem('financialYear', value);
    }
    get financialYear() {
        const financialYear = this.storage.getItem('financialYear');
        try {
            return financialYear ? financialYear : '';
        } catch (error) {
            return '';
        }
    }
    get userName() {
        return this.username;
    }
    set userName(value: string) {
        this.username = value;
    }

    get permissionList() {
        const permissions = this.storage.getItem('permissionSet');
        try {
            return permissions ? JSON.parse(atob(permissions)) : [];
        } catch (error) {
            return [];
        }
    }
    set permissionList(value: Array<any>) {
        this.storage.setItem('permissionSet', btoa(JSON.stringify(value)));
    }

    set email(value: string) {
        this.storage.setItem('email', value);
    }
    get email() {
        const email = this.storage.getItem('email');
        try {
            return email ? email : '';
        } catch (error) {
            return '';
        }
    }
    set token(value: string) {
        this.storage.setItem('token', value);
    }
    get token() {
        const token = this.storage.getItem('token');
        try {
            return token ? token : '';
        } catch (error) {
            return '';
        }
    }
    set refreshToken(value: string) {
        this.storage.setItem('refreshToken', value);
    }
    get refreshToken() {
        const refreshToken = this.storage.getItem('refreshToken');
        try {
            return refreshToken ? refreshToken : '';
        } catch (error) {
            return '';
        }
    }
    get userId() {
        return localStorage.getItem(environment.userId);
    }

    set userId(value: string) {
        localStorage.setItem(environment.userId, value);
    }

    get navigatingFromAccountPage() {
        return this.storage.getItem(environment.navigatingFromAccountPage);
    }

    set navigatingFromAccountPage(value: string) {
        this.storage.setItem(environment.navigatingFromAccountPage, value);
    }

    constructor(private jwtHelper: JwtHelperService) { }
    clear() {
        this.storage.clear();
        localStorage.clear();
        this.username = '';
    }

    where(dataSource: Array<any>, key: string, value: any): Object {
        let result = null;
        for (let index = 0; index < dataSource.length; index++) {
            if (dataSource[index][key] === value) {
                result = dataSource[index];
                break;
            }
        }
        return result;
    }

    removeItem(dataSource: Array<any>, key: string, value: any): Array<any> {
        let pos = -1;
        for (let index = 0; index < dataSource.length; index++) {
            if (dataSource[index][key] === value) {
                pos = index;
                break;
            }
        }
        if (pos > -1) {
            dataSource.splice(pos, 1);
        }
        return dataSource;
    }
    removePayItem(dataSource: Array<any>, key: string, key2: string, value: any, value2: any): Array<any> {
        let pos = -1;
        for (let index = 0; index < dataSource.length; index++) {
            if (dataSource[index][key] === value && dataSource[index][key2] === value2) {
                pos = index;
                break;
            }
        }
        if (pos > -1) {
            dataSource.splice(pos, 1);
        }
        return dataSource;
    }

    menuIcon(value: string) {
        const permissions = this.permissionList;
        const menu = this.where(permissions, 'name', value) as any;
        return menu.icon;
    }

    getDisplayDate(date: Date): string {
        const months = ['January', 'February', 'March', 'April', 'May', 'June',
            'Jul', 'August', 'September', 'October', 'November', 'December'
        ];
        let result = '';
        if (date) {
            result = date.getDate() + ' ' + months[date.getMonth()] + ', ' + date.getFullYear();
        }
        return result;
    }

    generateId(dataSource: Array<any>, key: string): number {
        if (dataSource && dataSource.length > 0) {
            return dataSource.map((item) => item[key]).sort((val1, val2) => {
                if (val1 > val2) {
                    return val1;
                } else {
                    return val2;
                }
            })[0] + 1;
        } else {
            return 1;
        }
    }

    sortData(dataSource: Array<any>, key: string): Array<any> {
        const temp = Array<any>().concat(dataSource);
        return temp.sort((a: any, b: any) => {
            if (a[key] > b[key]) {
                return 1;
            } else if (a[key] < b[key]) {
                return -1;
            } else { return 0; }
        });
    }

    deleteData(dataSource: Array<any>, key: string, value: any): void {
        if (dataSource && dataSource.length && key && value) {
            for (let index = 0; index < dataSource.length; index++) {
                if (dataSource[key] === value) {
                    dataSource.splice(index, 1);
                    break;
                }
            }
        }
    }

    isTokenExpired(): boolean {
        return this.jwtHelper.isTokenExpired(this.token);
    }
}
