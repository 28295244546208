import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Response } from 'app/shared/models/response/response.model';
import { ForgotPassword, UserRequest } from 'app/shared/models/user/user.model';
import { SharedService } from '../shared.Service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserService {
    static REGISTER = `${environment.apiPath}users/` + 'register';
    static FORGOT_PASSWORD_INITIATION = `${environment.apiPath}users/` + 'forgot-password-initiation';
    static FORGOT_PASSWORD_COMPLETION = `${environment.apiPath}users/` + 'forgot-password-completion';
    static UPDATE_USER = `${environment.apiPath}users/`;
    static GET_ALLUSERS = `${environment.apiPath}users/` + '/';
    static GET_USERS_BY_ROLES = `${environment.apiPath}users/` + 'users-by-roles';
    static SAVE_ADDRESSES = `${environment.apiPath}users/` + 'addresses/save';
    static GET_ADDRESS_TYPES = `${environment.apiPath}` + 'address-types';
    static SEARCH_USERS = `${environment.apiPath}users/` + 'search';
    static GET_PATH6 = `${environment.apiPath}` + '';
    static GET_USER_BY_ID = `${environment.apiPath}users`;
    static DELETE_USER = `${environment.apiPath}users/`;
    static CHANGE_PASSWORD = `${environment.apiPath}users/` + 'change-password';
    static SUPERADMIN_RESET_PASSWORD = `${environment.apiPath}reset-user-password?api-version=v1`;

    constructor(private apiService: ApiService, private sharedService: SharedService) { }

    /**
     * Register the new user.
     * @param user the user data to be register.
     * @returns response model
     */
    register(user: UserRequest): Observable<any> {
        return this.apiService.postData(UserService.REGISTER, user)
            .pipe(map((result: any) => {
                const res = this.populateSuccessResponse(result);
                return res;
            }), catchError((err: any) => {
                return of(this.populateErrorResponse(err));
            }));
    }

    /**
     * Search the users.
     * @param searchObj the search query request model.
     * @returns response model
     */
    getUserList(searchObj: any): Observable<any> {
        const request = {
            UserName: searchObj.userName, IsActive: searchObj.status,
        };
        return this.apiService.postData(UserService.SEARCH_USERS, request)
            .pipe(map((response: any) => {
                const res = this.populateSuccessResponse(response);
                if (res.failure) {
                    return res;
                } else {
                    res.result = this.populateUserData(res.result);
                }
                return res;
            }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Gets the user details by user id.
     * @returns response model
     */
    getUserDetailsByUserId(): Observable<any> {
        const apiUrl = `${UserService.GET_USER_BY_ID}/${this.sharedService.appUserId}`;
        return this.apiService.getDetails(apiUrl).pipe(map((response: any) => {
            const res = this.populateSuccessResponse(response);
            return res;
        }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Saves the user addresses.
     * @param address the user addresses object.
     * @returns response model
     */
    saveAddress(address: any): Observable<any> {
        return this.apiService.postData(UserService.SAVE_ADDRESSES, address)
            .pipe(map((result: any) => {
                const res = this.populateSuccessResponse(result);
                return res;
            }), catchError((err: any) => {
                return of(this.populateErrorResponse(err));
            }));
    }

    /**
     * Updates the user details.
     * @param user the user data.
     * @returns response model
     */
    updateUser(user: UserRequest): Observable<any> {
        return this.apiService.updateData(UserService.UPDATE_USER, user).pipe(map((result: any) => {
            const res = this.populateSuccessResponse(result);
            return res;
        }), catchError((err: any) => {
            return of(this.populateErrorResponse(err));
        }));
    }

    /**
     * Deactivates/Activates the user.
     * @param userName the user name.
     * @param id the user id.
     * @param status the user's status.
     * @returns response model
     */
    deleteUserDetails(userName: string, id: number, status: boolean): Observable<any> {
        const apiUrl = `${UserService.DELETE_USER}/${id}/${(!status)}/${this.sharedService.userFullName}`;
        return this.apiService.deleteData(apiUrl).pipe(map((response: any) => {
            const res = this.populateSuccessResponse(response);
            if (res.failure) {
                return res;
            } else {
                res.result = userName + (!status
                    ? environment.successMessages.Activate_Success
                    : environment.successMessages.Deactivate_Success);
            }
            return res;
        }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Gets the users by roles.
     * @returns response model
     */
    getUsersByRole(): Observable<any> {
        return this.apiService.getDetails(UserService.GET_USERS_BY_ROLES).pipe(map((response: any) => {
            const res = this.populateSuccessResponse(response);
            return res;
        }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Gets the address types.
     * @returns response model
     */
    getAddressTypes(): Observable<any> {
        return this.apiService.getDetails(UserService.GET_ADDRESS_TYPES).pipe(map((response: any) => {
            const res = this.populateSuccessResponse(response);
            return res;
        }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Forgot password initiation request.
     * @param req the forgot request object.
     * @returns response model
     */
    forgotPassword(req: ForgotPassword): Observable<any> {
        const request = {
            Email: req.EMail
        };
        return this.apiService.postData(UserService.FORGOT_PASSWORD_INITIATION, request).pipe(map((result: any) => {
            const res = this.populateSuccessResponse(result);
            return res;
        }), catchError((err: any) => {
            return of(this.populateErrorResponse(err));
        }));
    }

    /**
     * Resets the user's password.
     * @param request the request object.
     * @returns response model
     */
    resetPassword(request: any): Observable<any> {
        return this.apiService.postData(UserService.FORGOT_PASSWORD_COMPLETION, request).pipe(map((result: any) => {
            const res = this.populateSuccessResponse(result);
            return res;
        }), catchError((err: any) => {
            return of(this.populateErrorResponse(err));
        }));
    }

    /**
     * Changes the user's password.
     * @param changePassword the change password request object.
     * @returns response model
     */
    changePassword(changePassword: any): Observable<any> {
        return this.apiService.postData(UserService.CHANGE_PASSWORD, changePassword).pipe(map((response: any) => {
            const res = this.populateSuccessResponse(response);
            return res;
        }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Changes the user's password by superadmin. 
     * @param request the reset password request object.
     * @returns 
     */
    changePasswordBySuperAdmin(request: any): Observable<any> {
        return this.apiService.postData(UserService.SUPERADMIN_RESET_PASSWORD, request).pipe(map((response: any) => {
            const res = this.populateSuccessResponse(response);
            return res;
        }), catchError((err: HttpErrorResponse) => of(this.populateErrorResponse(err))));
    }

    /**
     * Populates the user data.
     * @param users the user list.
     * @returns response model
     */
    private populateUserData(users: Array<any>): Array<any> {
        const userData = [];
        for (let index = 0; index < users.length; index++) {
            const user = {
                sNo: index + 1,
                id: users[index].id,
                userName: users[index].userName,
                fullName: users[index].fullName,
                firstName: users[index].firstName,
                lastName: users[index].lastName,
                storeName: users[index].storeName,
                store: users[index].storeId,
                role: users[index].roleId,
                roleName: users[index].roleName,
                email: users[index].email,
                mobileNo: users[index].phoneNumber,
                isActive: users[index].isActive ? 'Yes' : 'No',
                userAddress: this.populateUserAddressList(users[index].userAddressDetails || [])
            };
            userData.push(user);
        }
        return userData;
    }

    /**
     * Populates the user's address details.
     * @param users the user list.
     * @returns response model
     */
    private populateUserAddressList(address: any[]) {
        const addressList = [];
        for (let index = 0; index < address.length; index++) {
            const value = {
                Sno: index + 1,
                AddressId: address[index].addressId,
                AddressType: address[index].addressType,
                AddressTypeName: address[index].addressTypeName,
                Name: address[index].name,
                StateProvinceId: address[index].stateProvinceId,
                Country: address[index].country,
                State: address[index].state,
                Address1: address[index].address1,
                Address2: address[index].address2,
                City: address[index].city,
                LandMark: address[index].landMark,
                PostalCode: address[index].postalCode,
                FaxNumber: address[index].faxNumber,
                IsActive: true
            };
            addressList.unshift(value);
        }
        return addressList;
    }

    /**
     * Populates the Success Response.
     * @param response response object from API
     * @returns response model
     */
    private populateSuccessResponse(response: any) {
        const res: Response = {
            failure: !!response.isError,
            success: !!response.succeeded,
            error: !!response.isError ? response.responseException.exceptionMessage : response.message,
            result: response.data
        };
        if (response.statusCode === 400) {
            res.error = environment.errorMessages[400];
        }
        return res;
    }

    /**
     * Populates the Error Response.
     * @param error error response object from API.
     * @returns response model
     */
    private populateErrorResponse(error: any) {
        const res: Response = {
            failure: true,
            success: false,
            error: error.error.responseException.exceptionMessage.detail|| environment.errorMessages[error.statusCode],
            result: null
        };
        return res;
    }

}
