import { Injectable } from '@angular/core';
import swal, { SweetAlertResult, SweetAlertIcon } from 'sweetalert2';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AlertService {

    // For synchronous warning kind of error alert message
    warnAlert(message: string): void {
        swal.fire({
            title: 'Alert',
            icon: 'warning',
            text: message,
            allowOutsideClick: false,
            confirmButtonText: 'Ok',
            showConfirmButton: true
        });
        this.alert(message, 'warning', 'Alert');
    }

    // For asynchronous warning kind of error alert message
    warnAlertAsync(message: string): Observable<boolean> {
        let response = false;
        const observable = new Observable<boolean>((observer) => {
            this.alert(message, 'warning', 'Alert').then((res: SweetAlertResult) => {
                if (res.value) {
                    response = true;
                }
                observer.next(response);
            });
        });
        return observable;
    }

    // For synchronous success alert message
    successAlert(message: string): void {
        this.alert(message, 'success', 'Request Finished!');
    }

    // For asynchronous success alert message
    successAlertAsync(message: string): Observable<boolean> {
        let response = false;
        const observable = new Observable<boolean>((observer) => {
            this.alert(message, 'success', 'Request Finished!').then((res: SweetAlertResult) => {
                if (res.value) {
                    response = true;
                }
                observer.next(response);
            });
        });
        return observable;
    }

    // For asyncronous confirmation message
    confirmAsync(message: string): Observable<boolean> {
        let response = false;
        const observable = new Observable<boolean>((observer) => {
            swal.fire({
                title: 'Confirm',
                icon: 'info',
                text: message,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText:
                    '<i class="fa fa-thumbs-up"></i> Ok!',
                cancelButtonText:
                    '<i class="fa fa-thumbs-down"></i> Cancel!'
            }).then((res: SweetAlertResult) => {
                if (res.value) {
                    response = true;
                }
                observer.next(response);
            });
        });
        return observable;
    }
    printAsync(message: string): Observable<boolean> {
        let response = false;
        const observable = new Observable<boolean>((observer) => {
            swal.fire({
                title: 'Confirm',
                icon: 'info',
                text: message,
                allowOutsideClick: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText:
                    '<i class="fa fa-thumbs-up"></i> Yes!',
                cancelButtonText:
                    '<i class="fa fa-thumbs-down"></i> Cancel!'
            }).then((res: SweetAlertResult) => {
                if (res.value) {
                    response = true;
                }
                observer.next(response);
            });
        });
        return observable;
    }

    private alert(message: string, type: string, title: string) {
        return swal.fire({
            title: title,
            icon: type as SweetAlertIcon,
            text: message,
            allowOutsideClick: false,
            confirmButtonText: 'Ok',
            showConfirmButton: true
        });
    }
}
