import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Response } from 'app/shared/models/response/response.model';
import { SharedService } from '../shared.Service';
import { DesignOrder } from 'app/shared/models/designorder/designorder.model';
import { DatePipe } from '@angular/common';

@Injectable()
export class DesignOrderService {
    static GET_PATH = `${environment.apiPath}` + 'getdesignorderstatustypes';
    static GET_PATH1 = `${environment.apiPath}` + 'getdesignorderstatetypes';
    static GET_PATH2 = `${environment.apiPath}` + 'getdesignarts';
    static GET_PATH3 = `${environment.apiPath}` + 'getdesignorders';
    static GET_PATH4 = `${environment.apiPath}` + 'getalldesignorders';
    static GET_PATH5 = `${environment.apiPath}` + 'getdesignartsbyid';
    static GET_PATH6 = `${environment.apiPath}` + 'getdesignartsbydefault';
    static GET_PATH7 = `${environment.apiPath}` + 'getsearchusersbyrole';
    static GET_PATH8 = `${environment.apiPath}` + 'getallhistorywithorder';
    static GET_PATH9 = `${environment.apiPath}` + 'getallhistorywithoutorder';
    static GET_PATH10 = `${environment.apiPath}` + 'getalljoborders';
    static GET_PATH11 = `${environment.apiPath}` + 'getworkorder';
    static SAVE_PATH = `${environment.apiPath}` + 'savedesignorder';
    static SAVE_PATH1 = `${environment.apiPath}` + 'updatedesignorder';
    static SAVE_PATH2 = `${environment.apiPath}` + 'updatedesignorderitems';
    static SAVE_PATH3 = `${environment.apiPath}` + 'joborderdetails';
    static SAVE_PATH4 = `${environment.apiPath}` + 'saveworkorder';
    static UPDATE_PATH = `${environment.apiPath}` + 'updatehistorynotification';
    static UPDATE_PATH1 = `${environment.apiPath}` + 'updatedesignhistory';
    static UPDATE_PATH2 = `${environment.apiPath}` + 'readallnotifications';
    static DELETE_PATH = `${environment.apiPath}` + 'deletedesignorder';
    static DELETE_PATH1 = `${environment.apiPath}` + 'deletedesignart';
    static DELETE_PATH2 = `${environment.apiPath}` + 'deletejoborder';
    constructor(private apiService: ApiService, private datepipe: DatePipe, private sharedService: SharedService) { }

    getDesignOrder(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                Id: searchObj
            };
            this.apiService.postData(DesignOrderService.GET_PATH3, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateDesignOrderList(result.designOrderResponses), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    private populateDesignOrderList(designs: any): any {
        const orderList = [];
        for (let index = 0; index < designs.length; index++) {
            const order = {
                sNo: index + 1,
                id: designs[index].id,
                orderNo: designs[index].designOrderNumber,
                artNo: designs[index].artNo,
                weight: designs[index].weight,
                uQCId: designs[index].uqcId,
                title: designs[index].title,
                categoryId: designs[index].categoryId,
                asigneeId: designs[index].asigneeId,
                category: designs[index].category,
                designFor: designs[index].designFor,
                state: designs[index].designState,
                designStateId: designs[index].designStateId,
                designStatusId: designs[index].designStatusId,
                designStatus: designs[index].designStatus,
                designArtItems: this.populateDesignArtItems(designs[index].designArtItems),
                designItems: this.populateDesignItems(designs[index].designOrderItems),
                designHistories: this.populateDesignArtHistories(designs[index].designHistories)
            };
            orderList.push(order);
        }
        return orderList;
    }

    getAllDesignOrder(): Observable<any> {
        const observable = new Observable((observer) => {
            let apiUrl = DesignOrderService.GET_PATH4;
            apiUrl = apiUrl + '/' + this.sharedService.userRole;
            this.apiService.getDetails(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAllDesignOrderList(result.designOrderResponses), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }
 
    private populateAllDesignOrderList(designs: any): any {
        const orderList = [];
        for (let index = 0; index < designs.length; index++) {
            const order = {
                id: designs[index].id,
                orderNo: designs[index].designOrderNumber,
                categoryId: designs[index].categoryId,
                category: designs[index].category,
                artNo: designs[index].artNo,
                weight: designs[index].weight,
                designFor: designs[index].designFor,
                title: designs[index].title,
                message: designs[index].message,
                asigneeId: designs[index].asigneeId,
                designStateId: designs[index].designStateId,
                designStatusId: designs[index].designStatusId,
                status: designs[index].designStatus,
                state: designs[index].designState,
                createdOn: this.datepipe.transform(designs[index].createdOn, 'yyyy-MM-dd hh:mm'),
            };
            orderList.push(order);
        }
        return orderList;
    }

    getAllDesignArtsById(id: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                Id: id,
            };
            this.apiService.postData(DesignOrderService.GET_PATH5, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAllDesignArtList(result.designArtResponses), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    private populateAllDesignArtList(arts: any): any {
        const artList = [];
        for (let index = 0; index < arts.length; index++) {
            const order = {
                sNo: index + 1,
                id: arts[index].id,
                orderId: arts[index].id,
                category: arts[index].category,
                artNo: arts[index].artNo,
                weight: arts[index].weight,
                uQCId: arts[index].uqcId,
                title: arts[index].title,
                asignee: arts[index].asignee,
                asigneeName: arts[index].asigneeName,
                artStatus: arts[index].artStatus,
                artState: arts[index].artState,
                message: arts[index].message,
                createdOn: this.datepipe.transform(arts[index].updatedOn, 'yyyy-MM dd-hh:mm'),
            };
            artList.push(order);
        }
        return artList;
    }

    getAllDesignArtsByDefault(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(DesignOrderService.GET_PATH6).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAllDesignArtList(result.designArtResponses), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getAllHistoryByUserId(id: string): Observable<any> {
        let apiUrl = DesignOrderService.GET_PATH8;
        apiUrl = apiUrl + '/' + id;
        const observable = new Observable((observer) => {
            this.apiService.getDetails(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAllHistoryList(result.getAllHistories), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getAllHistoryByOrder(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(DesignOrderService.GET_PATH9).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateAllHistoryList(result.getAllHistories), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    updateNotificationHistory(id: number): Observable<any> {
        const observable = new Observable((observer) => {
            let apiUrl = DesignOrderService.UPDATE_PATH;
            apiUrl = apiUrl + '/' + id;
            this.apiService.getDetails(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    readAllNotification(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(DesignOrderService.UPDATE_PATH2).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getSearchDesignArts(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                Id: searchObj.id,
                designOrderId: searchObj.designOrderId,
                CategoryId: searchObj.categoryId,
                Title: searchObj.title,
                ArtStateId: searchObj.artStateId,
                ArtStatusId: searchObj.artStatusId,
            };
            this.apiService.postData(DesignOrderService.GET_PATH2, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateDesignOrderList(result), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    saveDesignOrder(designorder: DesignOrder): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(DesignOrderService.SAVE_PATH, designorder).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.stringValue };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    updateDesignOrder(designorder: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(DesignOrderService.SAVE_PATH1, designorder).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.stringValue };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    saveJobOrder(jobOrder: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(DesignOrderService.SAVE_PATH3, jobOrder).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.stringValue };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    saveWorkOrder(workOrder: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(DesignOrderService.SAVE_PATH4, workOrder).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    updateDesignOrderItems(designorderItems: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(DesignOrderService.SAVE_PATH2, designorderItems).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.stringValue };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    updateDesignHistory(history: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(DesignOrderService.UPDATE_PATH1, history).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true, result: 'Order'
                            + environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    deleteDesignOrder(id: number, title: string): Observable<any> {
        let apiUrl = DesignOrderService.DELETE_PATH;
        let modifiedBy = this.sharedService.appUserId;
        apiUrl = apiUrl + '/' + id + '/' + modifiedBy;
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: title + environment.successMessages.Delete_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    deleteJobOrder(id: number, status: boolean, title: string): Observable<any> {
        let apiUrl = DesignOrderService.DELETE_PATH2;
        apiUrl = apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: title + (status
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getorderStatusTypes(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(DesignOrderService.GET_PATH).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateOrderDesignStatusTypes(result.types), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getArtStatusTypes(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(DesignOrderService.GET_PATH1).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateOrderDesignStatusTypes(result.types), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getSearchUsersByRole(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(DesignOrderService.GET_PATH7).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateUsersList(result.usersByRoles), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getPlaceOrderProducts(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                JobNo: searchObj.JobNo, Status: +searchObj.status, IsActive: searchObj.isActive
            };
            let apiUrl = DesignOrderService.GET_PATH10;
            this.apiService.postData(apiUrl, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateJobOrders(result.jobOrders), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    getWorkOrderDetails(id: number): Observable<any> {
        const observable = new Observable((observer) => {
            let apiUrl = DesignOrderService.GET_PATH11 + '/' + id;
            this.apiService.getDetails(apiUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result, error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response = this.populateErrorResponse(error);
                observer.next(response);
            });
        });
        return observable;
    }

    private populateDesignItems(items: any) {
        const itemList = [];
        for (let index = 0; index < items.length; index++) {
            const value = {
                SNo: index + 1,
                Id: items[index].id,
                ProductId: items[index].productId,
                ProductName: items[index].productName,
                Quantity: items[index].quantity
            };
            itemList.push(value);
        }
        return itemList;
    }
    private populateAllHistoryList(history: any): any {
        const artList = [];
        for (let index = 0; index < history.length; index++) {
            const order = {
                sNo: index + 1,
                id: history[index].orderId,
                notificationId: history[index].notificationId,
                historyId: history[index].historyId,
                title: history[index].title,
                message: history[index].message,
                orderNo: history[index].orderNo,
                artNo: history[index].artNo,
                weight: history[index].weight,
                category: history[index].category,
                designFor: history[index].designFor,
                designStateId: history[index].designStateId,
                designStatusId: history[index].designStatusId,
                state: history[index].state,
                status: history[index].status,
                imageString: history[index].imageUrl,
                asigneeId: history[index].asigneeId,
                asigneeName: history[index].asigneeName,
                createdOn: this.datepipe.transform(history[index].createdOn, 'yyyy-MM dd-hh:mm'),
            };
            artList.push(order);
        }
        return artList;
    }

    private populateDesignArtHistories(history: any) {
        const historyList = [];
        for (let index = 0; index < history.length; index++) {
            const value = {
                createdOn: this.datepipe.transform(history[index].createdOn, 'yyyy-MM dd-hh:mm'),
                notificationId: history[index].notificationId,
                historyId: history[index].historyId,
                id: history[index].id,
                orderNo: history[index].designOrderNumber,
                artNo: history[index].artNo,
                title: history[index].title,
                categoryId: history[index].categoryId,
                comment: history[index].comment,
                asigneeId: history[index].asigneeId,
                asigneeName: history[index].asigneeName,
                artState: history[index].artStateName,
                artStatus: history[index].artStatus,
                modifiedBy: history[index].modifiedBy,
                designAttachments: this.populateDesignArtAttachments(history[index].designAttachments),
            };
            historyList.push(value);
        }
        return historyList;
    }
    private populateDesignArtItems(items: any) {
        const itemList = [];
        for (let index = 0; index < items.length; index++) {
            const value = {
                sNo: index + 1,
                Id: items[index].id,
                ProductId: items[index].productId,
                ProductName: items[index].productName,
                Quantity: items[index].quantity
            };
            itemList.push(value);
        }
        return itemList;
    }
    private populateDesignArtAttachments(art: any) {
        const valueList = [];
        for (let index = 0; index < art.length; index++) {
            const value = {
                id: art[index].id,
                imageString: art[index].imageUrl,
                type: art[index].mimeType,
            };
            valueList.push(value);
        }
        return valueList;
    }

    private populateOrderDesignStatusTypes(types: any): any {
        const typeList = [];
        for (let index = 0; index < types.length; index++) {
            const store = {
                name: types[index].key,
                id: types[index].value,
            };
            typeList.push(store);
        }
        return typeList;
    }
    
    private populateUsersList(users: any): any {
        const userList = [];
        for (let index = 0; index < users.length; index++) {
            const user = {
                id: users[index].id,
                name: users[index].name,
            };
            userList.push(user);
        }
        return userList;
    }

    private populateJobOrders(jobOrder: any) {
        const jobOrderList = [];
        for (let index = 0; index < jobOrder.length; index++) {
            const value = {
                sNo: index + 1,
                id: jobOrder[index].id,
                jobOrder: jobOrder[index].jobOrderNumber,
                status: jobOrder[index].status,
                isActive: jobOrder[index].isActive ? 'No' : 'Yes',
                createdOn: this.datepipe.transform(jobOrder[index].createdOn, 'yyyy-MM dd-hh:mm'),
                jobOrderItems: this.populatePlaceOrderProducts(jobOrder[index].jobOrderItems),
            };
            jobOrderList.push(value);
        }
        return jobOrderList;
    }

    private populatePlaceOrderProducts(jobItems: any): any {
        const productList = [];
        for (let index = 0; index < jobItems.length; index++) {
            const product = {
                SNo: jobItems[index].sNo,
                Id: jobItems[index].id,
                ProductId: jobItems[index].productId,
                ProductName: jobItems[index].productName,
                SKU: jobItems[index].sku,
                Quantity: jobItems[index].quantity,
                Details: jobItems[index].details,
                workOrderStatus: jobItems[index].workOrder,
                Weight: jobItems[index].Weight,
                workid: jobItems[index].workOrderId,
                workOrder: jobItems[index].workOrderNumber,
                WorkOrderState:jobItems[index].workOrderStatus,
                createdOn: jobItems[index].createdOn !== null ?
                    this.datepipe.transform(jobItems[index].createdOn, 'yyyy-MM-dd') : null,
            };
            productList.push(product);
        }
        return productList;
    }
        
    /**
     * Populates the Success Response.
     * @param response response object from API
     * @returns response model
     */
     private populateSuccessResponse(response: any) {
        const res: Response = {
            failure: !!response.isError,
            success: !!response.succeeded,
            error: !!response.isError ? response.responseException.exceptionMessage : response.message,
            result: response.data
        };
        if (response.statusCode === 400) {
            res.error = environment.errorMessages[400];
        }
        return res;
    }

    /**
     * Populates the Error Response.
     * @param error error response object from API.
     * @returns response model
     */
    private populateErrorResponse(error: any) {
        const res: Response = {
            failure: true,
            success: false,
            error: error.error.responseException.exceptionMessage.detail || environment.errorMessages[error.statusCode],
            result: null
        };
        return res;
    }

}
